import { useSelector } from "react-redux";
import { useState } from "react";
import styled, { css } from "styled-components";

import { ButtonSecondary } from "../../../components/Parts";
import DeductionModal from "./DeductionModal";
import JudgeModal from "./JudgeModal";
import ConfirmButton from "./ConfirmButton";
import { useMemo } from "react";

const Td = styled.td`
  min-width: 48px;
  min-height: 48px;
  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
      &:hover {
        background: rgba(110, 65, 226, 0.44);
      }
    `};
  ${({ $red }) =>
    $red &&
    css`
      background: #fecaca;
    `};
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
  }
  background: ${({ current }) =>
    current ? "rgba(110, 65, 226, 0.14) !important" : ""};
`;

const CurrentTr = styled(Tr)`
  font-size: 14px;
`;

const JudgeButton = styled(ButtonSecondary)`
  margin-top: 10px;
`;

const Competitor = ({ competitor, position }) => {
  const [showDeductionModal, setShowDeductionModal] = useState(false);

  const [changableScore, setChangableScore] = useState(null);

  const judges = useSelector((rootState) => rootState.competition.judges);

  const currentCompetitorId = useSelector((rootState) => {
    return rootState.competitors.find((c) => c.completed === false)?.id;
  });

  const current = currentCompetitorId === competitor.id;

  const difficultyDeductions =
    competitor.scores["D1"] && competitor.scores["D2"]
      ? (
          (parseFloat(competitor.scores["D1"].deduction) +
            parseFloat(competitor.scores["D2"].deduction)) /
          2
        ).toFixed(3)
      : null;

  const onChangableScoreClick = (score) => {
    if (!score || !current) return;
    setChangableScore(score);
  };

  const isDScoreValid = useMemo(() => {
    if (competitor.scores["D1"]?.meta && competitor.scores["D2"]?.meta) {
      return (
        Object.keys(competitor.scores["D1"].meta).filter((key) => {
          return (
            competitor.scores["D1"].meta[key] !==
            competitor.scores["D2"].meta[key]
          );
        }).length === 0
      );
    }

    return !competitor.scores["D1"]?.value.toString() ||
      !competitor.scores["D2"]?.value.toString()
      ? true
      : competitor.scores["D1"]?.value === competitor.scores["D2"]?.value;
  }, [competitor.scores]);

  const isDDeductionValid =
    !competitor.scores["D1"]?.deduction.toString() ||
    !competitor.scores["D2"]?.deduction.toString()
      ? true
      : competitor.scores["D1"]?.deduction ===
        competitor.scores["D2"]?.deduction;

  return (
    <>
      <Tr current={current}>
        <td rowSpan={current ? 2 : 1}>
          {position}
          {!!changableScore && (
            <JudgeModal
              closeModal={() => setChangableScore(null)}
              score={changableScore}
            />
          )}
        </td>
        <td rowSpan={current ? 2 : 1}>{competitor.name}</td>
        <Td
          $clickable={current && !!competitor.scores["E1"]}
          onClick={() => onChangableScoreClick(competitor.scores["E1"])}
        >
          {competitor.scores["E1"]?.value.toString() || "-"}
        </Td>
        <Td
          $clickable={current && !!competitor.scores["E2"]}
          onClick={() => onChangableScoreClick(competitor.scores["E2"])}
        >
          {competitor.scores["E2"]?.value.toString() || "-"}
        </Td>
        {!!judges["E3"] && (
          <Td
            $clickable={current && !!competitor.scores["E3"]}
            onClick={() => onChangableScoreClick(competitor.scores["E3"])}
          >
            {competitor.scores["E3"]?.value.toString() || "-"}
          </Td>
        )}
        {!!judges["E4"] && (
          <Td
            $clickable={current && !!competitor.scores["E4"]}
            onClick={() => onChangableScoreClick(competitor.scores["E4"])}
          >
            {competitor.scores["E4"]?.value.toString() || "-"}
          </Td>
        )}
        <td rowSpan={current ? 2 : 1}>
          <strong>{competitor.e_total_score || "-"}</strong>
        </td>
        <Td
          $clickable={current && !!competitor.scores["A1"]}
          onClick={() => onChangableScoreClick(competitor.scores["A1"])}
        >
          {competitor.scores["A1"]?.value.toString() || "-"}
        </Td>
        <Td
          $clickable={current && !!competitor.scores["A2"]}
          onClick={() => onChangableScoreClick(competitor.scores["A2"])}
        >
          {competitor.scores["A2"]?.value.toString() || "-"}
        </Td>
        {!!judges["A3"] && (
          <Td
            $clickable={current && !!competitor.scores["A3"]}
            onClick={() => onChangableScoreClick(competitor.scores["A3"])}
          >
            {competitor.scores["A3"]?.value.toString() || "-"}
          </Td>
        )}
        {!!judges["A4"] && (
          <Td
            $clickable={current && !!competitor.scores["A4"]}
            onClick={() => onChangableScoreClick(competitor.scores["A4"])}
          >
            {competitor.scores["A4"]?.value.toString() || "-"}
          </Td>
        )}
        <td rowSpan={current ? 2 : 1}>
          <strong>{competitor.a_total_score || "-"}</strong>
        </td>
        {!!judges["D1"] && (
          <Td
            $red={!isDScoreValid}
            rowSpan={current ? 2 : 1}
            $clickable={current && !!competitor.scores["D1"]}
            onClick={() =>
              onChangableScoreClick([
                competitor.scores["D1"],
                competitor.scores["D2"],
              ])
            }
          >
            {competitor.scores["D1"]?.value.toString() || "-"}
          </Td>
        )}
        {!!judges["D2"] && (
          <Td
            rowSpan={current ? 2 : 1}
            $red={!isDScoreValid}
            $clickable={current && !!competitor.scores["D2"]}
            onClick={() =>
              onChangableScoreClick([
                competitor.scores["D1"],
                competitor.scores["D2"],
              ])
            }
          >
            {competitor.scores["D2"]?.value.toString() || "-"}
          </Td>
        )}
        <td rowSpan={current ? 2 : 1}>
          <strong>
            {competitor.d_total_score ? competitor.d_total_score : "-"}
          </strong>
        </td>
        <Td
          $red={!isDDeductionValid}
          $clickable={!isDDeductionValid}
          rowSpan={current ? 2 : 1}
          onClick={() =>
            isDDeductionValid
              ? undefined
              : onChangableScoreClick([
                  competitor.scores["D1"],
                  competitor.scores["D2"],
                ])
          }
        >
          {difficultyDeductions || "-"}
        </Td>
        <td rowSpan={current ? 2 : 1}>
          {competitor.scores["L1"]?.deduction.toString() || "-"}
        </td>
        <td rowSpan={current ? 2 : 1}>
          {competitor.scores["L2"]?.deduction.toString() || "-"}
        </td>
        <td rowSpan={current ? 2 : 1}>
          <div>{competitor.scores["C"]?.deduction}</div>
          {current && (
            <>
              <JudgeButton
                onClick={() => {
                  setShowDeductionModal(true);
                }}
              >
                +
              </JudgeButton>
              {showDeductionModal && (
                <DeductionModal
                  competitorId={competitor.id}
                  closeModal={() => {
                    setShowDeductionModal(false);
                  }}
                />
              )}
            </>
          )}
        </td>
        <td rowSpan={current ? 2 : 1}>
          <strong style={{ whiteSpace: "nowrap" }}>
            <div>{competitor.final_score || "-"}</div>
            {current && <ConfirmButton competitorId={competitor.id} />}
          </strong>
        </td>
      </Tr>
      {current && (
        <CurrentTr current={current}>
          <td>{judges["E1"]}</td>
          <td>{judges["E2"]}</td>
          {!!judges["E3"] && <td>{judges["E3"]}</td>}
          {!!judges["E4"] && <td>{judges["E4"]}</td>}
          <td>{judges["A1"]}</td>
          <td>{judges["A2"]}</td>
          {!!judges["A3"] && <td>{judges["A3"]}</td>}
          {!!judges["A4"] && <td>{judges["A4"]}</td>}
        </CurrentTr>
      )}
    </>
  );
};

export default Competitor;
