import Pusher from "pusher-js";
import Echo from "laravel-echo";
import api from "./api";

window.Pusher = Pusher;

const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY,
  // Pusher fallback:
  // cluster: "eu",
  // forceTLS: true,
  // Soketi:
  wsHost: process.env.REACT_APP_PUSHER_HOST,
  wsPort: process.env.REACT_APP_PUSHER_PORT,
  wssPort: process.env.REACT_APP_PUSHER_PORT,
  ...(process.env.NODE_ENV === "development" && { forceTLS: false }),
  // encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        api
          .post("/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((data) => {
            callback(false, data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    };
  },
});

export default echo;
