import styled, { keyframes } from "styled-components";
import useTranslations from "../../../hooks/useTranslations";
const pulse = keyframes`
  0% {
		transform: scale(0.93);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(0.93);
	}
`;
const DNotification = styled.div`
  font-size: 18px;
  margin-left: 20px;
  animation: ${pulse} 2s infinite;
`;
const Notification = ({ notification, judge }) => {
  const translations = useTranslations();
  if (judge === "D" && notification) {
    return <DNotification>{translations["Koreguokite"]}</DNotification>;
  }
  if (
    (notification === "-" && judge !== "E") ||
    (notification === "+" && judge === "E")
  ) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width="30"
        height="30"
        fill="red"
      >
        <path
          fillRule="evenodd"
          d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
  if (
    (notification === "+" && judge !== "E") ||
    (notification === "-" && judge === "E")
  ) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width="30"
        height="30"
        fill="red"
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  return null;
};

export default Notification;
